import isFunction from 'lodash/isFunction';

export const LOAD = 'redux-ducks/dashboard/LOAD';
export const LOAD_SUCCESS = 'redux-ducks/dashboard/LOAD_SUCCESS';
export const LOAD_FAIL = 'redux-ducks/dashboard/LOAD_FAIL';

export const LOAD_BIRTHDAY = 'redux-ducks/dashboard/LOAD_BIRTHDAY';
export const LOAD_BIRTHDAY_SUCCESS = 'redux-ducks/dashboard/LOAD_BIRTHDAY_SUCCESS';
export const LOAD_BIRTHDAY_FAIL = 'redux-ducks/dashboard/LOAD_BIRTHDAY_FAIL';

export const LOAD_DOCS = 'redux-ducks/dashboard/LOAD_DOCS';
export const LOAD_DOCS_SUCCESS = 'redux-ducks/dashboard/LOAD_DOCS_SUCCESS';
export const LOAD_DOCS_FAIL = 'redux-ducks/dashboard/LOAD_DOCS_FAIL';

export const LOAD_CHART = 'redux-ducks/dashboard/LOAD_CHART';
export const LOAD_CHART_SUCCESS = 'redux-ducks/dashboard/LOAD_CHART_SUCCESS';
export const LOAD_CHART_FAIL = 'redux-ducks/dashboard/LOAD_CHART_FAIL';

export const LOAD_NOTIFICATIONS = 'redux-ducks/dashboard/LOAD_NOTIFICATIONS';
export const LOAD_NOTIFICATIONS_SUCCESS = 'redux-ducks/dashboard/LOAD_NOTIFICATIONS_SUCCESS';
export const LOAD_NOTIFICATIONS_FAIL = 'redux-ducks/dashboard/LOAD_NOTIFICATIONS_FAIL';

export const LOAD_MESSAGES = 'redux-ducks/dashboard/LOAD_MESSAGES';
export const LOAD_MESSAGES_SUCCESS = 'redux-ducks/dashboard/LOAD_MESSAGES_SUCCESS';
export const LOAD_MESSAGES_FAIL = 'redux-ducks/dashboard/LOAD_MESSAGES_FAIL';

export const LOAD_ORGSTAT = 'redux-ducks/dashboard/LOAD_ORGSTAT';
export const LOAD_ORGSTAT_SUCCESS = 'redux-ducks/dashboard/LOAD_ORGSTAT_SUCCESS';
export const LOAD_ORGSTAT_FAIL = 'redux-ducks/dashboard/LOAD_ORGSTAT_FAIL';

export const LOAD_WORKSTAT = 'redux-ducks/dashboard/LOAD_WORKSTAT';
export const LOAD_WORKSTAT_SUCCESS = 'redux-ducks/dashboard/LOAD_WORKSTAT_SUCCESS';
export const LOAD_WORKSTAT_FAIL = 'redux-ducks/dashboard/LOAD_WORKSTAT_FAIL';

export const LOAD_DATA = 'redux-ducks/dashboard/LOAD_DATA';
export const LOAD_DATA_SUCCESS = 'redux-ducks/dashboard/LOAD_DATA_SUCCESS';
export const LOAD_DATA_FAIL = 'redux-ducks/dashboard/LOAD_DATA_FAIL';

const LOAD_WORK_HOURS = 'redux-ducks/dashboard/LOAD_WORK_HOURS';
const LOAD_WORK_HOURS_SUCCESS = 'redux-ducks/dashboard/LOAD_WORK_HOURS_SUCCESS';
const LOAD_WORK_HOURS_FAIL = 'redux-ducks/dashboard/LOAD_WORK_HOURS_FAIL';

export const SET_DEFAULT = 'redux-ducks/dashboard/SET_DEFAULT';

const initialState = {
  data: {
    birthdays: [],
    documents: [],
    chart: [['month', 'scheduled', 'filled'], ['Jan', 0, 0]],
    orgStat: [],
    notifications: [],
    messages: [],
    workStat: [],
    lastUpdate: '',
  },
  paginationOrg: {
    page: 1,
    total: 1,
    total_pages: 1,
  },
  paginationWorker: {
    page: 1,
    total: 1,
    total_pages: 1,
  },
};

/**
 * Load default data for dashboard
 * @param {*} paramsUrl
 */
const loadData = (paramsUrl) => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`v3/planners/dashboard?${paramsUrl}`)
});

/*
 * Load request
 */
export const loadChart = (paramsUrl = '') => ({
  types: [LOAD_CHART, LOAD_CHART_SUCCESS, LOAD_CHART_FAIL],
  promise: (client) => client.get(`v3/planners/dashboard/shifts_stats?${paramsUrl}`)
});

export const loadOrgStat = (paramsUrl = '') => ({
  types: [LOAD_ORGSTAT, LOAD_ORGSTAT_SUCCESS, LOAD_ORGSTAT_FAIL],
  promise: (client) => client.get(`v3/planners/dashboard/organizations_stats?${paramsUrl}`),
});

export const loadWorkStat = (paramsUrl = '') => ({
  types: [LOAD_WORKSTAT, LOAD_WORKSTAT_SUCCESS, LOAD_WORKSTAT_FAIL],
  promise: (client) => client.get(`v3/planners/dashboard/workers_stats?${paramsUrl}`),
});

const loadBirthday = () => ({
  types: [LOAD_BIRTHDAY, LOAD_BIRTHDAY_SUCCESS, LOAD_BIRTHDAY_FAIL],
  promise: (client) => client.get('v3/planners/dashboard/birthdays')
});

const loadDocs = () => ({
  types: [LOAD_DOCS, LOAD_DOCS_SUCCESS, LOAD_DOCS_FAIL],
  promise: (client) => client.get('v3/planners/dashboard/expiring_documents')
});

const loadNotifications = () => ({
  types: [LOAD_NOTIFICATIONS, LOAD_NOTIFICATIONS_SUCCESS, LOAD_NOTIFICATIONS_FAIL],
  promise: (client) => client.get('v3/planners/dashboard/notifications?limit=3')
});

const loadMessages = () => ({
  types: [LOAD_MESSAGES, LOAD_MESSAGES_SUCCESS, LOAD_MESSAGES_FAIL],
  promise: (client) => client.get('v3/planners/dashboard/messages?limit=3')
});

export const loadWorkBufferTime = (paramsUrl = '') => ({
  types: [LOAD_WORK_HOURS, LOAD_WORK_HOURS_SUCCESS, LOAD_WORK_HOURS_FAIL],
  promise: (client) => client.get(`v3/planners/dashboard/workers_violations/buffer_time?${paramsUrl}`)
});

export const loadWorkMinPerWeek = (paramsUrl = '') => ({
  types: [LOAD_WORK_HOURS, LOAD_WORK_HOURS_SUCCESS, LOAD_WORK_HOURS_FAIL],
  promise: (client) => client.get(`v3/planners/dashboard/workers_violations/min_per_week?${paramsUrl}`)
});

export const loadWorkMaxPerWeek = (paramsUrl = '') => ({
  types: [LOAD_WORK_HOURS, LOAD_WORK_HOURS_SUCCESS, LOAD_WORK_HOURS_FAIL],
  promise: (client) => client.get(`v3/planners/dashboard/workers_violations/max_per_week?${paramsUrl}`)
});

export const loadWorkRollingAvg = (paramsUrl = '') => ({
  types: [LOAD_WORK_HOURS, LOAD_WORK_HOURS_SUCCESS, LOAD_WORK_HOURS_FAIL],
  promise: (client) => client.get(`v3/planners/dashboard/workers_violations/rolling_avg?${paramsUrl}`)
});

/**
 * Load dashboard common info
 */
export const loadCommonData = (paramsUrl) => (dispatch) => dispatch(loadData(paramsUrl));

/**
 * Load information for dashboard
 */
export const loadDashboardInfo = () => (dispatch) => {
  dispatch(loadBirthday());
  dispatch(loadDocs());
  dispatch(loadMessages());
  dispatch(loadNotifications());
  return Promise.resolve(true);
};

/**
 * Set default state
 */
export const setDefault = () => ({ type: SET_DEFAULT });

//
// Mutations
// *************************************
const updateBirthdaysInfo = (action) => (state) => {
  const { data, included } = action.result;

  const birthdays = data.map((el) => {
    const { date } = el.attributes;
    const { workers } = el.relationships;
    const ids = workers.data.map((element) => element.id);
    const users = included.filter((worker) => ids.includes(worker.id));
    return {
      date,
      users
    };
  });

  return ({
    ...state,
    data: {
      ...state.data,
      birthdays,
    },
  });
};

const updateDocumentsInfo = (action) => (state) => {
  const { data } = action.result;

  const documents = data.map((doc) => ({
    ...doc.attributes,
    loadable_type: doc.attributes.loadable_type.toLowerCase(),
  }));

  return ({
    ...state,
    data: {
      ...state.data,
      documents,
    },
  });
};

const updateNotificationsInfo = (action) => (state) => {
  const { data, included } = action.result;
  const notifications = data.map((notification) => {
    const includedWorker = included.find((work) => work.id === notification.relationships.worker.data?.id);

    return ({
      ...notification.attributes,
      worker: {
        ...(includedWorker && includedWorker.attributes)
      }
    });
  });

  return ({
    ...state,
    data: {
      ...state.data,
      notifications,
    }
  });
};

const updateMessagesInfo = (action) => (state) => {
  const { data, included } = action.result;
  const messages = data.map((message) => {
    const workerData = included.find((worker) => worker.attributes.id === message.attributes.data?.worker_id) || {};

    return {
      ...message.attributes,
      data: {
        ...message.attributes.data,
        full_name: workerData.attributes?.full_name || '',
        photo: workerData.attributes?.photo || '',
      }
    };
  });

  return ({
    ...state,
    data: {
      ...state.data,
      messages,
    }
  });
};

const updateChartInfo = (action) => (state) => {
  const { data } = action.result;
  const { shifts } = data.attributes;
  const [info, ...dataShifts] = shifts;
  const scheduled = info[2];
  const filled = info[3];
  const objChart = dataShifts.map((el) => ({
    name: el[1],
    [scheduled]: el[2],
    [filled]: el[3]
  }));

  return ({
    ...state,
    data: {
      ...state.data,
      chart: objChart,
      lastUpdate: data.attributes.updated_at || ''
    },
  });
};

const updateOrgStatInfo = (action) => (state) => {
  const { data, meta } = action.result;
  const orgStat = data.map((el) => el.attributes);

  return ({
    ...state,
    data: {
      ...state.data,
      orgStat,
    },
    paginationOrg: { ...meta.pagination }
  });
};

const updateWorkStatInfo = (action) => (state) => {
  const { data, meta } = action.result;
  const workStat = data.map((el) => el.attributes);

  return ({
    ...state,
    data: {
      ...state.data,
      workStat,
    },
    paginationWorker: { ...meta.pagination }
  });
};

const setDefaultSuccess = () => initialState;

const actionsLookup = {
  [LOAD_ORGSTAT_SUCCESS]: (state, action) => updateOrgStatInfo(action)(state),
  [LOAD_WORKSTAT_SUCCESS]: (state, action) => updateWorkStatInfo(action)(state),
  [LOAD_BIRTHDAY_SUCCESS]: (state, action) => updateBirthdaysInfo(action)(state),
  [LOAD_DOCS_SUCCESS]: (state, action) => updateDocumentsInfo(action)(state),
  [LOAD_NOTIFICATIONS_SUCCESS]: (state, action) => updateNotificationsInfo(action)(state),
  [LOAD_MESSAGES_SUCCESS]: (state, action) => updateMessagesInfo(action)(state),
  [LOAD_CHART_SUCCESS]: (state, action) => updateChartInfo(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
